import React, { useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { FaCircleNotch } from "react-icons/fa"
import axios from "axios"
import ContactSchema from "../helpers/contact-schema"
import { Formik, Form, Field, ErrorMessage } from "formik"

const AgentContactForm = ({ agentId }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [response, setResponse] = useState("")
  const [creatingLeadAjax, setCreatingLeadAjax] = useState(false)

  return (
    <div className="agent-contact-container flex flex-col justify-evenly items-center text-white px-5 xl:px-24 bg-dkgray">
      <div className="font-extralight tracking-wider text-3xl xl:text-5xl">
        <span>Contact</span>
      </div>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={async values => {
          setCreatingLeadAjax(true)

          if (!executeRecaptcha) {
            setResponse("Recaptcha Issue, Try Again Later...")
            setCreatingLeadAjax(false)

            return
          }

          try {
            const recaptchaToken = await executeRecaptcha("about_agent")
            await axios({
              url: process.env.FOUNDATION_LEAD_CREATE_ENDPOINT,
              method: "POST",
              data: {
                recaptchaToken,
                formValues: values,
                propertyInfo: {
                  listingAgentMlsId: agentId,
                  createdFrom: "Summit Colorado Christies - About Agent",
                },                
              },
            })

            setResponse(
              `Your contact was sent to us, we'll get in touch briefly with you!`
            )
          } catch (error) {
            setResponse(
              `Sorry, there was an issue sending your contact request...`
            )
          }

          setCreatingLeadAjax(false)
        }}
      >
        {({ handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            name="contact-agent"
            method="POST"
            className="text-center"
          >
            <div className="flex flex-col md:flex-row justify-evenly items-center w-full items-container">
              <div className="flex flex-col w-full md:w-1/2 md:pr-1.5 md:pl-3 lg:pr-4 xl:pr-5">
                <div className="flex flex-col md:flex-row md:justify-end w-full">
                  <div className="py-2.5 md:px-2.5 lg:w-60">
                    <Field
                      name="firstName"
                      placeholder="FIRST NAME"
                      className="bg-transparent border-b border-white font-normal xl:text-xs w-full pb-2"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      render={msg => (
                        <div className="agent-contact-container__error">*</div>
                      )}
                    />
                  </div>
                  <div className="py-2.5 md:px-2.5 lg:w-60">
                    <Field
                      name="lastName"
                      placeholder="LAST NAME"
                      className="bg-transparent border-b border-white font-normal xl:text-xs w-full pb-2"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      render={msg => (
                        <div className="agent-contact-container__error">*</div>
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row md:justify-end w-full">
                  <div className="py-2.5 md:px-2.5 lg:w-60">
                    <Field
                      name="email"
                      placeholder="EMAIL ADDRESS"
                      className="bg-transparent border-b border-white font-normal xl:text-xs w-full pb-2"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      render={msg => (
                        <div className="agent-contact-container__error">*</div>
                      )}
                    />
                  </div>
                  <div className="py-2.5 md:px-2.5 lg:w-60">
                    <Field
                      name="phone"
                      placeholder="PHONE NUMBER"
                      className="bg-transparent border-b border-white font-normal xl:text-xs w-full pb-2"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      render={msg => (
                        <div className="agent-contact-container__error">*</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full md:w-1/2 self-stretch md:pr-3 md:pl-1.5 lg:pl-4 xl:pl-5">
                <div className="flex flex-row md:justify-start py-2.5 w-full h-full">
                  <Field
                    as="textarea"
                    rows="4"
                    name="message"
                    placeholder="Add a message here.."
                    className="agent-contact-container__textarea w-full xl:w-120 h-full xl:text-xs px-1 py-2"
                  ></Field>
                  <ErrorMessage
                    name="message"
                    component="div"
                    render={msg => (
                      <div className="agent-contact-container__error">*</div>
                    )}
                  />
                </div>
              </div>
            </div>

            <button
              aria-disabled={creatingLeadAjax}
              disabled={creatingLeadAjax}
              className="text-xs font-black tracking-wider uppercase py-3 w-1/2 md:w-1/3 agent-submit-button"
              type="submit"
            >
              {creatingLeadAjax ? (
                <FaCircleNotch className="cha-cl__spin-icon" />
              ) : (
                "SUBMIT"
              )}
            </button>
            {response && <span className="response-white">{response}</span>}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AgentContactForm
