import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AgentHeroGallery from "../components/agent-hero-gallery"
import AgentBio from "../components/agent-bio"
import AgentContactForm from "../components/agent-contact-form"
import AgentListings from "../components/agent-listings"
import AgentVideo from "../components/agent-video"
import AgentTestimonials from "../components/agent-testimonials"

const Agent = ({ data, location }) => {
  const listings = {
    active: [],
    closed: [],
  }

  const residentialListings = [];
  residentialListings.push(...data.mainAgentListings.edges);
  residentialListings.push(...data.coAgentListings.edges);
  residentialListings.push(...data.mainBuyerListings.edges);
  residentialListings.push(...data.coBuyerListings.edges);

  const agent = data.agent;

  let testimonials = [];

  if (agent) {
    testimonials = [
      agent.testimonialOne,
      agent.testimonialTwo,
      agent.testimonialThree,
      agent.testimonialFour,
      agent.testimonialFive,
    ].filter(t => t);
  }

  const orderActiveVsClosed = (item) => {
    if (item.node.StandardStatus === "Closed") {
      listings.closed.push(item.node)
    } else {
      listings.active.push(item.node)
    }
  }

  residentialListings.forEach(orderActiveVsClosed)

  return (
    <Layout>
      <>
        <Seo title={agent.fullName} location={location} />
        {agent && (
          <>
            <AgentHeroGallery listings={listings.active}></AgentHeroGallery>
            <AgentBio
              agentHeadshot={
                agent.photo
                  ? process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 420).replace('HEIGHT', 420 / 0.75) + agent.photo.url
                  : null
              }
              agentFirstName={agent.firstName}
              agentLastName={agent.lastName}
              agentFullName={agent.fullName}
              agentTitle={agent.title}
              agentprefferedPhone={agent.prefferedPhone}
              agentOfficePhone={agent.officePhone}
              agentMobilePhone={agent.mobilePhone}
              agentEmail={agent.email}
              agentAddress1={agent.address1}
              agentAddress2={agent.address2}
              agentCity={agent.city}
              agentStateOrProvince={agent.stateOrProvince}
              agentPostalCode={agent.postalCode}
              agentOfficeName={agent.officeName}
              agentDescription={agent.agentBio || ""}
            ></AgentBio>

            <AgentContactForm agentId={agent.mlsId}></AgentContactForm>

            {listings.active.length > 0 || listings.closed.length > 0 ? (
              <AgentListings listings={listings}></AgentListings>
            ) : null}

            {
              agent.agentVideo && agent.agentVideo.url ?
                <AgentVideo videoSrc={agent.agentVideo.url}></AgentVideo>
                :
                ''
            }

            {testimonials.length ? (
              <AgentTestimonials
                agentName={agent.firstName}
                testimonials={testimonials}
              ></AgentTestimonials>
            ) : null}
          </>
        )}
      </>
    </Layout>
  )
}

export const query = graphql`
  query($memberMLS: String!, $mlsId: String!) {
    mainAgentListings: allListing(
      filter: { ListAgentMlsId: { eq: $memberMLS } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    coAgentListings: allListing(
      filter: { CoListAgentMlsId: { eq: $memberMLS } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    mainBuyerListings: allListing(
      filter: { BuyerAgentMlsId: { eq: $memberMLS } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    coBuyerListings: allListing(
      filter: { CoBuyerAgentMlsId: { eq: $memberMLS } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    agent(mlsId: { eq: $mlsId }) {
      firstName
      lastName
      fullName
      prefferedPhone
      officePhone
      mobilePhone
      email
      city
      stateOrProvince
      postalCode
      address1
      address2
      officeName
      path
      mlsId
      photo {
        url
      }
      agentVideo {
        url
      }
      title
      agentBio
      testimonialOne
      testimonialTwo
      testimonialThree
      testimonialFour
      testimonialFive
    }
  }
`

export default Agent
